<template>
    <div class="JointReport">
      <div class="current">
        <img src="../../assets/lbdqjb.png" alt="">
        <p>{{detail.course_name}}</p>
        <div class="classification">
          逻辑
        </div>
        <div class="money">
          ￥{{detail.discount_price}}
        </div>
      </div>
      <div class="Optional" v-for='item in data'>
        <img src="../../assets/lbkxtb.png" alt="">
        <ul>
          <li v-for='items in item.course'   @click="checkedBox(items.id,items.is_buy,items)">
            <p>{{items.course_name}}</p>
            <div class="classification">
              {{item.name}}
            </div>
            <div class="money">
              ￥{{items.discount_price}}
            </div>
            <div class="choice" v-if='items.is_buy==0'>
               <img src="../../assets/lbxz.png" alt="" v-if='arr.includes(items.id)'>
               <img src="../../assets/lbwx.png" alt="" v-else>
            </div>
             <div class="choice" v-if='items.is_buy==1'>
               <img src="../../assets/lbwx.png" alt="" >
            </div>
          </li>
        </ul>
      </div>
      <div class="rule" @click='rules'>
        <img src="../../assets/lbhdgz.png" alt="">
        <span>活动规则</span>
      </div>
      <div class="rules" v-if='rule==true' @click='rules'>
        <div class="text">
         <p>联报规则</p> 
         <img src="../../assets/guanbi.png" alt="">
         <span>{{campaign_remark}}</span>
        </div>
      </div>
      <div class="footer">
         <div class="top"><span v-for='item in reduction_rule'>满{{item.full_money}}减{{item.reduction_money}}&nbsp;&nbsp;&nbsp;</span></div>
         <div class="bottom">
           <p class="yj">原价：￥{{discount_price}}</p>
           <p class='sf'  v-if='arr.length<=1'>实付：<span>￥{{discount_price}}</span> </p>
           <p class='sf'  v-if='arr.length>1' >实付：<span>￥{{this.money}}</span> </p>
           <div class="btn" v-if='arr.length<=1'>
             两门起报
           </div>
           <div class="btns" v-if='arr.length>1' @click='signUp'>
             立即支付
           </div>
         </div>
      </div>
    </div>
</template>

<script>
import request from "../../plugin/request";
  export default {
    data(){
     return {
       discount_price:'',
       rule:false,
       id:'',
       arr:[],
       arrs:[],
       data: '',
       course_id:'',
       reduction_rule:'',
       url:'',
       detail:'',
       is_buy:1,
       campaign_remark:'',
       reduction_rules:[],
       money:''
     }
  },
  mounted(){
    let currentQuery = this.$route.query;
    this.id=currentQuery.id;
    this.course_id=currentQuery.course_id
    this.url=currentQuery.url
    this.$nextTick(()=> {
      this.comment()
      this.details()

    })
  },
  methods:{
  	checkedBox(i,is_buy,items){
      
      if(is_buy==1) {
        this.stopPropatation
      } else if(is_buy==0)  {
        if(this.arr.includes(i)){
				this.arr=this.arr.filter(function (ele){return ele != i;});
			}else{
				this.arr.push(i);
      }
      if(this.arrs.includes(items)){
				this.arrs=this.arrs.filter(function (ele){return ele != items;});
			}else{
				this.arrs.push(items);
      }
      let sum = 0;
      this.arrs.forEach(item =>{
          sum = sum + parseInt(item.discount_price)
      })
      this.discount_price=sum
      // console.log(this.reduction_rule)
      // console.log(sum)
      for(var i=0;i<this.reduction_rule.length;i++) {
        // console.log(this.reduction_rules[i])
        if(this.reduction_rule[i].full_money>=sum) {
          	this.reduction_rules.push(this.reduction_rule[i]);
        }

      }
      // 满减后的价格
      this.money=(sum* 100-this.reduction_rules[this.reduction_rules.length-1].reduction_money* 100)/100
      console.log(1000-999.99)
      }
			
    },
    rules() {
      this.rule=!this.rule
    },
    async details() {
      const {data} = await request.post('/app/course/details',{ id:this.course_id,is_h5:1})
      console.log(data)
      this.detail=data
      this.discount_price=data.discount_price
      this.arr[0]=data.id
      this.arrs[0]={
        discount_price:data.discount_price
      }
    },

  // 联报列表
  async comment() {
      const data=  await request.post('/app/reduction/courseList',{course_id:this.course_id,id:this.id})
      this.data=data.data.reduction_course
      this.reduction_rule=data.data.reduction_rule
      this.campaign_remark=data.data.campaign_remark
     if(data.code==505) {
        this.$router.push({ path:'/login?url='+this.url+'&&id='+this.course_id})
     }
    //  -------------------------------
       this.reduction_rule = this.reduction_rule.sort(function(a, b) {  
         return b.full_money - a.full_money;
       })

    },
     // 支付界面
   async  signUp() {
      var ua = navigator.userAgent.toLowerCase();
      
      if (ua.match(/MicroMessenger/i)=="micromessenger") {
           Toast('点击右上角"···"选择用浏览器打开');  
      } else {
          this.$router.push({ path:'/payment?id='+this.arr+'&JointReport_id='+this.id+'&group=0'+'&discount_price='+this.money})
      }
      
      // this.download=true
    
    },
  }
}
</script>

<style  lang='less' scoped>
.JointReport {
  width: 100%;
  height: 100%;
   .current {
  position: relative;
  background: url(../../assets/lbdq.png);
  background-size: 100% 100%;
  width: 9.48rem;
  height: 1.69rem;
  margin-top:.27rem;
  margin-left: .27rem;
    img {
      width: .87rem;
      height: .87rem;
      position: absolute;
      top:.13rem;
      left: .13rem;
    }
    p {
      padding: 0;
      margin:0;
      font-size: .37rem;
      margin-left: .55rem;
      line-height: 1.69rem;
    }
    .classification {
      position: absolute;
      top:.4rem;
      right: 1.23rem;
      width: .76rem;
      height: .36rem;
      border: .01rem solid #B7BDC8;
      border-radius:.07rem;
      font-size: .27rem;
      font-weight: 500;
      color: #B7BDC8;
      line-height: .36rem;
      text-align: center;
    }

    .money  {
      position: absolute;
      top:1rem;
      right: 1.23rem;
      font-size: .37rem;
      color: #FF4A26;
    }
   }
   
   .Optional {
  margin-bottom:.4rem;
  padding:0 .47rem .23rem ;
  box-sizing: border-box;
  position: relative;
  background: #EBEFF2;
  box-shadow:
   -3px 0 1px 0px #fff, /*左边阴影*/
   0 -3px 1px 0px #fff , /*顶部阴影*/
   0 4px 1px -1px #b9bec1, /*底部阴影*/
   4px 0 1px -1px  #b9bec1; /*右边阴影*/
  border-radius: .27rem;
  width: 9.2rem;
  margin-top:.27rem;
  margin-left: .4rem;
   img {
     width: .87rem;
     height: .87rem;
     position: absolute;
     top:0;
     left:0;
   }
   li:first-child {
     border-top:none;
   }
    li {
      position: relative;
      border-top:1px solid  #8C98B3;
       p {
         padding: 0;
         margin:0;
         font-size: .37rem;
         line-height: 1.69rem;
       }
       .classification {
         position: absolute;
         top:.4rem;
         right: .72rem;
         width: .76rem;
         height: .36rem;
         border: .01rem solid #B7BDC8;
         border-radius:.07rem;
         font-size: .27rem;
         font-weight: 500;
         color: #B7BDC8;
         line-height: .36rem;
         text-align: center;
       }
   
       .money  {
         position: absolute;
         top:1rem;
         right: .72rem;
         font-size: .37rem;
         color: #FF4A26;
       }

       .choice  {
         width: .41rem;
         height: .41rem;
         position: absolute;
         top:50%;
         transform: translateY(-50%);
         right: -0.07rem;
         img {
           width: 100%;
           height: 100%;
         }
       }
    }
   }
   
   .rule  {
  float: right;
  margin-right: .68rem;
  margin-bottom:2.13rem;

  img {
    width: .41rem;
    height: .41rem;
    margin-right:.13rem ;
    vertical-align:middle
  }
  span {
    font-size: .27rem;
    color: #8DBBE0;
        vertical-align:middle

  }
   }
   
  .rules {
  position:absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background:rgba(0,0,0,0.3);
  overflow: hidden;
  z-index: 1000;

   .text {
     width:6.53rem;
     background: #EBEFF2;
     border-radius: .27rem;
     position: absolute;
     top:50%;
     left: 50%;
     transform: translate(-50%,-50%);
     padding:0 .47rem .47rem;
     box-sizing: border-box;
     p {
       width: 100%; 
       font-size:.37rem;
       font-weight: 500;
       color: #8C98B3;
       margin:.4rem 0;
       text-align: center;
     }

     img {
       position: absolute;
       top:.28rem;
       right: .28rem;
       width: .35rem;
       height: .35rem;
     }

     span  {
      font-size: .32rem;
      font-weight: 500;
      color: #8C98B3;
      line-height: 1.5;
     }


   }
  }

  .footer {
    width: 100%;
    position: absolute;
    bottom:0;
    left: 0;
    background-color: #fff;
    overflow: hidden;

    .top {
      position: relative;
      width: 100%;
      // height:.77rem;
      background: #C6DEF1;
      line-height: .77rem;
      text-align: center;
      font-size: .27rem;
      font-weight: 500;
      color: #5A98CA;
      margin:0;

          span {
      font-size: .27rem;
      font-weight: 500;
      color: #5A98CA;
       }
    }

    .bottom {
      width: 100%;
      height: 1.31rem;
      background: #EBEFF2;
      box-shadow: -1px -1px 3px 0px #ADBFCE;
      overflow: hidden;
      position: relative;

      .yj {
        padding:0;
       font-size: .32rem;
       font-weight: 500;
       text-decoration: line-through;
       color: #8C98B3;;
       margin-left:.67rem;
       margin-top:.24rem;
       line-height: 0;
      }
      
      .sf {
       font-size: .37rem;
       font-weight: 500;
       color: #252525;
       padding:0;
       margin:0.10rem 0 0 .67rem;

       span {
       font-size: .43rem;
       font-weight: bold;
       color: #FF4A26;
       }
      }

      .btn {
       width: 3.2rem;
       height: .91rem;
       border: .03rem solid #B7BDC8;
       border-radius: .45rem;
       line-height: .91rem;
       text-align: center;
       position: absolute;
       top:50%;
       transform: translateY(-50%);
       right: .4rem;
       font-size: .4rem;
       color: #B7BDC8;
      }
      .btns {
       width: 3.2rem;
       height: .91rem;
       border: .03rem solid #2775FF;
       border-radius: .45rem;
       line-height: .91rem;
       text-align: center;
       position: absolute;
       top:50%;
       transform: translateY(-50%);
       right: .4rem;
       font-size: .4rem;
       color: #2775FF;
      }
    }
  }

}

</style>